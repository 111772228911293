<template>
  <div class="cash">
    <div class="box">
      <div class="menu flex jc_s">
        <a href="/cardCoucher" class="flex jc_s"><i class="iconfont iconmaijia_wode"></i>满减券</a>
        <a href="/cardcoucherDH" class="flex jc_s"><i class="iconfont iconyonghu1"></i>兑换券</a>
        <a href="/cardcoucherGB" class="flex jc_s now"><i class="iconfont icontuiguang-1"></i>贵宾券</a>
        <a href="/cardcoucherDJ" class="flex jc_s"><i class="iconfont iconkehu"></i>代金券</a>
      </div>
      <div class="p16">
        <div class="pb16 flex jc_b ai_c">
          <el-button type="primary" class="ssbtn" style="width: 6.75rem" @click="addBtn">添加</el-button>
          <div class="flex jc_end f14 col333 ai_c"></div>
        </div>
        <div class="table">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="valtable1" label="名称" width="140">
              <template slot-scope="scope">
                <div>{{ scope.row.title }}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="out90" label="起始卡号">
              <template slot-scope="scope">
                <div>{{ scope.row.card_number_start }}</div>
              </template>
            </el-table-column> -->
            <el-table-column prop="cname" label="批次" width="140">
              <template slot-scope="scope">
                <div @click="handleInfo(scope.row)" style="cursor: pointer; color: red">{{ scope.row.batch }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="in20" label="有效期" width="140">
              <template slot-scope="scope">
                <div>{{ scope.row.validity_time }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="in20" label="面额">
              <template slot-scope="scope">
                <div>{{ scope.row.money }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="in20" label="数量">
              <template slot-scope="scope">
                <div>{{ scope.row.card_quantity }}</div>
              </template>
            </el-table-column>

            <el-table-column prop="in20" label="已激活数">
              <template slot-scope="scope">
                <div>{{ scope.row.card_activation }}</div>
              </template>
            </el-table-column>

            <el-table-column prop="out90" label="是否补差">
              <template slot-scope="scope">
                <div>{{ scope.row.is_balance_pay == 1 ? '是' : '否' }}</div>
              </template>
            </el-table-column>

            <el-table-column prop="out90" label="制卡备注">
              <template slot-scope="scope">
                <div>{{ scope.row.remarks }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="out90" label="制卡人">
              <template slot-scope="scope">
                <div>{{ scope.row.admin_name }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="out90" label="制卡时间" width="140">
              <template slot-scope="scope">
                <div>{{ scope.row.created_at }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="out90" label="作废原因">
              <template slot-scope="scope">
                <div>{{ scope.row.cancel_reason }}</div>
              </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" width="240">
              <template slot-scope="scope">
                <el-button v-show="scope.row.status == -1" class="delbtn" type="info" disabled>作废</el-button>
                <el-button class="qxbtn mr10" type="danger" @click="toVeri(scope.row,'edit')">编辑</el-button>
                <el-button class="qxbtn mr10" type="danger" @click="toVeri(scope.row,'del')">删除</el-button>
                <!--  editBtn(scope.row)  delBtn(scope.row)-->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--table end-->

        <!-- 添加  编辑 -->
        <!-- dialog -->
        <el-dialog class="dialogHeaderno" width="625px" :visible.sync="dialogVisible" :before-close="handleClose">
          <div class="qxDialogHeader flex-column jc_c">
            <div class="flex jc_b ai_c">
              <span class="f16 colfff">{{ title }}</span>
              <i class="iconfont iconguanbi colfff f18" @click="dialogVisible = false"></i>
            </div>
          </div>
          <div class="ccmid">
            <el-form ref="form" :model="form">
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">名称：</span>
                <el-form-item prop="title">
                  <el-input placeholder="输入名称" v-model="form.title" auto-complete="off"></el-input>
                </el-form-item>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">批次：</span>
                <el-form-item prop="batch">
                  <el-input placeholder="输入批次" v-model="form.batch" auto-complete="off"></el-input>
                </el-form-item>
              </div>

              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">金额：</span>
                <el-form-item label="" prop="money">
                  <el-input placeholder="输入金额" v-model="form.money"></el-input>
                </el-form-item>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">起始卡号：</span>
                <el-form-item label="" prop="money">
                  <el-input placeholder="输入起始卡号" v-model="form.card_number_start"></el-input>
                </el-form-item>
              </div>

              <div v-if="form.card_type != 1" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">限定城市：</span>
                <el-form-item label="" prop="condition">
                  <!-- <el-cascader :options="city_list" v-model="form.city" :props="{ multiple: true, checkStrictly: true, label: 'cname', value: 'cid' }" clearable></el-cascader> -->
                  <!-- <el-cascader :options="city_list" v-model="form.city_cus" :props="{ multiple: true, label: 'cname', value: 'cid' }" clearable></el-cascader> -->

                  <el-select class="selectInput" @change="selectChange" v-model="form.city_cus" multiple filterable placeholder="请输入包含的城市名称">
                    <el-option v-for="item in city_list" :key="item.cid" :label="item.nm" :value="item.id"> </el-option>
                  </el-select>
                
                </el-form-item>
              </div>

              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">领取人：</span>
                <el-form-item prop="movie">
                  <!-- <el-select filterable v-model="form.receiver" multiple collapse-tags placeholder="请选择">
                    <el-option v-for="(item, index) in slect3" :key="index" :label="item.nickname" :value="item.id"> </el-option>
                  </el-select> -->

                  <el-select v-model="form.receiver" multiple filterable remote reserve-keyword placeholder="选择领取人id，不选为全部" :remote-method="remoteMethod1" :loading="loading">
                    <el-option v-for="item in slect3" :key="item.value" :label="item.id" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div v-if="form.card_type == 1" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">使用条件：</span>
                <el-form-item label="" prop="condition">
                  <el-input v-model="form.condition"></el-input>
                </el-form-item>
              </div>

              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">限定影城：</span>
                <el-form-item prop="movie">
                  <!-- <el-select filterable v-model="form.movie" multiple collapse-tags placeholder="请选择">
                    <el-option v-for="(item, index) in slect2" :key="index" :label="item.nm" :value="item.cinemaid"> </el-option>
                  </el-select> -->
                  <el-select v-model="form.movie" multiple filterable remote reserve-keyword placeholder="选择影城" :remote-method="remoteMethod2" :loading="loading">
                    <el-option v-for="item in slect2" :key="item.value" :label="item.nm" :value="item.cinemaid"> </el-option>
                  </el-select>
                  <span style="padding-left:10px">
                    <el-button @click="handleAll"  size="mini" round> 全选</el-button>
                  </span>
                    
                </el-form-item>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">指定影片：</span>
                <el-form-item prop="film">
                  <!-- <el-select filterable v-model="form.film" multiple collapse-tags placeholder="请选择">
                    <el-option v-for="(item, index) in slect1" :key="index" :label="item.nm" :value="item.mid"> </el-option>
                  </el-select> -->
                  <el-select v-model="form.film" multiple filterable remote reserve-keyword placeholder="选择影片，不选为全部" :remote-method="remoteMethod3" :loading="loading">
                    <el-option v-for="item in slect1" :key="item.value" :label="item.nm" :value="item.mid"> </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">有效期：</span>
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" v-model="form.validity_time" type="datetime" placeholder="选择日期时间"> </el-date-picker>
              </div>
              <div v-if="form.card_type == 1" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">制卡数量：</span>
                <el-input-number size="small" v-model="form.card_quantity" @change="handleChange" label=""></el-input-number>
              </div>
              <div v-if="form.card_type != 1" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">制卡总数：</span>
                <el-input-number size="small" v-model="form.card_quantity" @change="handleChange" label=""></el-input-number>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">最大领取数量：</span>
                <el-input-number size="small" v-model="form.card_user_quantity" @change="handleChange" label=""></el-input-number>
              </div>

              <div v-if="form.card_type != 1" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">补差：</span>
                <el-form-item prop="is_manual">
                  <el-radio-group v-model="form.is_balance_pay" class="xzradio">
                    <el-radio :label="1">补差</el-radio>
                    <el-radio :label="0">不补差</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div v-if="form.card_type != 1" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">连锁：</span>
                <el-form-item prop="is_manual">
                  <el-radio-group v-model="form.tp" class="xzradio">
                    <el-radio :label="2">全部</el-radio>
                    <el-radio :label="1">连锁</el-radio>
                    <el-radio :label="0">杂牌</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div v-if="form.card_type == 1" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">手动领取：</span>
                <el-form-item prop="is_manual">
                  <el-radio-group v-model="form.is_manual" class="xzradio">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div v-if="form.card_type == 1" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">弹窗：</span>
                <el-form-item prop="cpm">
                  <el-radio-group v-model="form.cpm" class="xzradio">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div v-if="form.card_type != 1" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">备注：</span>
                <el-form-item label="" prop="remarks">
                  <el-input type="textarea" placeholder="输入备注" v-model="form.remarks"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button @click="cancle">取 消</el-button>
            <el-button type="primary" @click="sure">确 定</el-button>
          </span>
        </el-dialog>

        <!-- 详情 -->
        <el-dialog class="dialogHeaderno" width="800px" :visible.sync="dialogInfo" :before-close="handleClose">
          <div class="qxDialogHeader flex-column jc_c">
            <div class="flex jc_b ai_c">
              <span class="f16 colfff">详情</span>
              <i class="iconfont iconguanbi colfff f18" @click="dialogInfo = false"></i>
            </div>
          </div>
          <div class="info_box" style="padding: 20px 80px">
            <div class="info_item">
              <div>名称：{{ details.title }}</div>
              <div>批次：{{ details.batch }}</div>
            </div>
            <div class="info_item">
              <div v-if="details.card_type == 1">类别：满减卡</div>
              <div v-if="details.card_type == 2">类别：兑换卡</div>
              <div v-if="details.card_type == 3">类别：贵宾卡</div>
              <div>金额：{{ details.money }}</div>
            </div>
            <div class="info_item">
              <div>手动领取：{{ details.is_manual == 1 ? '是' : '否' }}</div>
              <div>使用条件：{{ details.condition }}</div>
            </div>
            <div class="info_item">
              <div>有效期：{{ details.validity_time }}</div>
              <div>制卡数量：{{ details.card_quantity }}</div>
            </div>
            <div>弹窗：{{ details.cpm == 1 ? '是' : '否' }}</div>
            <div>领取人：{{ details.receiver_name }}</div>
            <div>限定影城：{{ details.movie_name }}</div>
            <div>指定影片：{{ details.film_name }}</div>
          </div>
        </el-dialog>

        <div class="page pt16">
          <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" :total="total"> </el-pagination>
        </div>
      </div>
    </div>

    <diaVeri @veriOk="veriOk" @handleClose="handleClose" :showVeri="showVeri" :title="title"></diaVeri>
  </div>
</template>

<script>
import headerData from '../common/ssBookHeader' //一级表头数据
import itemName from '../common/ssBookAttribute' //所需表格数据的属性值
import transform from '../common/export.js' //导出台账excel
import diaVeri from '@/components/verification'
export default {
  components: {
    diaVeri,
  },
  data() {
    return {
      // 短信验证
      showVeri: false,

      city_list: [],
      dialogVisible: false,
      dialogInfo: false,
      form: {
        title: '',
        batch: '',
        card_type: 3,
        money: '',
        receiver: '',
        is_manual: 1,
        condition: '',
        movie: '',
        card_quantity: 0,
        film: '',
        cpm: 1,
        expiration_day: 0,
        validity_time: '',
        card_user_quantity: 0,

        card_number_start: '',
        tp: 0,
        is_balance_pay: 0,
        city_cus: [],
        remarks: '',
      },
      slect1: [],
      slect2: [],
      slect3: [],
      title: '添加',
      details: {},

      valSelect: '',
      select: {
        dataType: '',
        cinameType: '',
        searchType: '',
        valSerch: '',
      },
      selectOption: {
        dataOption: [
          {
            value: '2',
            label: '全部',
          },
          {
            value: '1',
            label: '旧数据',
          },
          {
            value: '0',
            label: '新数据',
          },
        ],
        cinameOption: [
          {
            value: '2',
            label: '全部',
          },
          {
            value: '1',
            label: '连锁',
          },
          {
            value: '0',
            label: '非连锁',
          },
        ],
        searchOption: [
          {
            value: '1',
            label: '影院',
          },
          {
            value: '2',
            label: '城市',
          },
          {
            value: '3',
            label: '地址',
          },
        ],
      },
      // 表格数据
      tableData: [],
      // 搜索条件
      condition: {
        limit: 10,
        offset: 0,
        tp: '',
        is_old: '',
        nm: '',
        addr: '',
        cname: '',
      },
      item: '',
      pageSize: 10,
      currentPage: 1,
      total: 0,
      plczDialog: false,
      bjxgDialog: false,
      batch: {
        nm: '',
        tp: '',
        in20: '0.95',
        from20to30: '0.925',
        from30to40: '0.92',
        from40to50: '0.915',
        from50to60: '0.91',
        from60to70: '0.895',
        from70to80: '0.89',
        from80to90: ' 0.885',
        out90: '0.88',
      },

      loading: false,
      excel_list: [],
    }
  }, //data
  created() {
    this.getData()
    this.getCity()
    // this.getSelect1()
    // this.getSelect2()
    // this.getSelect3()
  },
  methods: {
    handleAll(){
      let arr = []
      this.slect2.map((item,indx)=>{
        console.log(this.form.movie,this.slect2)
        
        arr.push(item.cinemaid)
        this.form.movie = arr
      })
    },
    async selectChange(e) {
      await this.getSelect2('',this.form.city_cus)
    },
    toVeri(row,type) {
      this.editBtn(row)
      // let veri = this.$localStorage.getItem('veri') ? this.$localStorage.getItem('veri').veri : ''
      // if (veri) {
      //   this.veriOk(row,type)
      // } else {
      //   this.showVeri = true
      // }
    },
    veriOk(row,type) {
      if(type == 'edit'){
        this.editBtn(row)
      }else if(type == 'del'){
        this.delBtn(row)
      }
    },
    handleClose() {
      this.dialogVisible = false
      this.form.movie = []
      this.dialogInfo = false
      this.showVeri = false
    },
    // 导出为excel
    toExcel() {
      //调用我们封装好的方法，传3个参数过去，分别为：数据，文件名，回到函数(可根据自己需求决定回调是否需要)
      // multipleSelection  导出的数组
      // 勾选了传勾选的表格数据  依次为：表格数据，一级表头，二级表头，属性名，表名，回调
      // if (!this.$tools.isEmpty(this.multipleSelection)) {
      //   transform(this.multipleSelection,headerData,[],itemName, "卡券", this.callback);
      // } else {
      //   //否则传全部的
      let batch = ''
      if (!this.$tools.isEmpty(this.excel_list)) {
        batch = this.excel_list[0].batch
      }
      transform(this.excel_list, headerData, [], itemName, `${batch}批次卡密`, this.callback)
      // }
    },
    // 查看卡券详情
    handleInfo({ id }) {
      this.$router.push({
        path: '/cardcoucherList',
        query: {
          id,
          path: '/cardcoucherGB',
        },
      })
    },
    async remoteMethod1(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true
        await this.getSelect3(query)

        this.loading = false
      } else {
        this.options = []
      }
    },
    async remoteMethod2(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true

        await this.getSelect2(query,this.form.city_cus)

        this.loading = false
      } else {
        this.options = []
      }
    },
    async remoteMethod3(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true

        await this.getSelect1(query)

        this.loading = false
      } else {
        this.options = []
      }
    },

    handleChange(value) {
      console.log(value)
    },
    // 弹窗取消
    cancle() {
      this.dialogVisible = false
      this.form.movie = []
    },
    // 弹窗确定
    sure() {
      let ct_list = [],
        that = this
      this.form.city_cus.map((item, index) => {
        ct_list.push(item[1])
      })
      this.form.city = ct_list.join(',')
      if (this.title == '添加') {
        this.$api.sysSetCard(this.form).then((res) => {
          if (res.data.code == 0) {
            this.dialogVisible = false
            this.form.movie = []
            this.$message({
              type: 'success',
              message: '添加成功!',
            })

            setTimeout(() => {
              that.getData()
              that
                .$confirm('仅当前操作可下载excel, 是否继续?', '提示', {
                  confirmButtonText: '下载excel',
                  cancelButtonText: '不下载了',
                  type: 'warning',
                })
                .then(() => {
                  that.getVipData(res.data.data.card_id)
                })
                .catch(() => {
                  that.$message({
                    type: 'info',
                    message: '已取消',
                  })
                })
            }, 1500)
          }
        })
      } else {
        this.$api.sysSetCard(this.form).then((res) => {
          if (res.data.code == 0) {
            this.dialogVisible = false
            this.form.movie = []
            this.$message({
              type: 'success',
              message: '编辑成功!',
            })
            this.getData()
          }
        })
      }
    },
    getVipData(id) {
      let data = {
        limit: 100000,
        offset: 0,
        card_id: id,
        export: 1,
      }
      this.$api.getCardVip(data).then((res) => {
        if (res.data.code == 0) {
          this.excel_list = res.data.data.rows
          this.toExcel()
        }
      })
    },
    filter(val) {
      let offset = (val - 1) * this.pageSize
      let data = {
        limit: this.pageSize,
        offset: offset,
        card_type: 3,
      }
      return data
    }, //filter
    // 获取数据
    getData(val = 1) {
      const that = this
      let data = this.filter(val)
      this.$api.sysGetCard(data).then((res) => {
        if (res.data.code == 0) {
          that.total = res.data.data.count
          that.tableData = res.data.data.rows
        }
      })
    },
    // 获取影片
    async getSelect1(nm) {
      let res = await this.$api.sysGetData({
        nm,
      })
      if (res.data.code == 0) {
        this.slect1 = res.data.data
        // this.slect1.unshift({
        //   nm: '全部',
        //   mid: '',
        // })
      }
    },
    // 获取影城
    async getSelect2(nm,cname) {
      let res = await this.$api.sysGetCinema({
        nm,
        cid:cname
      })
      if (res.data.code == 0) {
        this.slect2 = res.data.data
        // this.slect2.unshift({
        //   nm: '全部',
        //   cinemaid: '',
        // })
      }
    },
    // 获取用户
    async getSelect3(id) {
      let res = await this.$api.sysGetUser({
        id,
      })

      if (res.data.code == 0) {
        this.slect3 = res.data.data
        // this.slect3.unshift({
        //   nickname: '全部',
        //   id: '',
        // })
      }
    },

    handleCurrentChange(val) {
      this.currentPage = val
      this.getData(val)
    },
    // 添加
    addBtn() {
      this.dialogVisible = true
      this.title = '添加'
      this.form = {
        title: '',
        batch: '',
        card_type: 3,
        money: '',
        receiver: '',
        is_manual: 1,
        condition: '',
        movie: [],
        card_quantity: 0,
        film: [],
        cpm: 1,
        expiration_day: 0,
        validity_time: '',
        card_user_quantity: 0,

        card_number_start: '',
        tp: 2,
        is_balance_pay: 1,
        is_yici: 0,
        city_cus: [],
        remarks: '',
      }
    },
    // 详情
    infoBtn({ id }) {
      this.dialogInfo = true
      this.$api.getCardDetail({ card_id: id }).then((res) => {
        this.details = res.data.data
      })
    },
    // 编辑
    editBtn(obj) {
      console.log(obj)
      this.dialogVisible = true
      this.title = '编辑'
      this.form = obj
    },
    delBtn(obj) {
      this.$confirm('正在进行删除操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log(obj)
          this.$api.sysSetCard({ ...obj, is_deleted: 1 }).then((res) => {
            if (res.data.code == 0) {
              this.dialogVisible = false
              this.form.movie = []
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.getData()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    getCity() {
      // this.$api.getCityLinkage().then((res) => {
      //   console.log('res', res)
      //   if (res.data.code == 0) {
      //     res.data.data.map((item, index) => {
      //       item.children.map((ite, idx) => {
      //         ite.cid = ite.rid
      //         ite.cname = ite.name
      //       })
      //     })
      //     this.city_list = res.data.data
      //   }
      // })
       this.$api.getCity().then((res) => {
        if (res.data.data) {
          this.city_list = res.data.data
          // console.log('this.form.city ', this.form.city)
        }
      })
    },

    // 搜索按钮
    searchBtn() {
      // this.currentPage = 1
      // this.condition.tp = this.select.cinameType
      // this.condition.is_old = this.select.dataType
      // switch (Number(this.select.searchType)) {
      // 	case 1:
      // 		this.condition.cname = ''
      // 		this.condition.addr = ''
      // 		this.condition.nm = this.select.valSerch
      // 		break;
      // 	case 2:
      // 		this.condition.nm = ''
      // 		this.condition.addr = ''
      // 		this.condition.cname = this.select.valSerch
      // 		break;
      // 	case 3:
      // 		this.condition.nm = ''
      // 		this.condition.cname = ''
      // 		this.condition.addr = this.select.valSerch
      // 		break;
      // 	default:
      // 		this.condition.nm = ''
      // 		this.condition.cname = ''
      // 		this.condition.addr = ''
      // 		this.select.valSerch = ''
      // }
      // // console.log(this.condition)
      // this.getData()
    },

    // 编辑按钮
    editConfirm() {
      //  this.$confirm('是否继续修改该配置?', '提示', {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消',
      // 	type: 'warning'
      // }).then(() => {
      // 	let data = {
      // 		id:this.item.id,
      // 		tp:this.item.tp,
      // 		in20:this.item.in20,
      // 		from20to30:this.item.from20to30,
      // 		from30to40:this.item.from30to40,
      // 		from40to50:this.item.from40to50,
      // 		from50to60:this.item.from50to60,
      // 		from60to70:this.item.from60to70,
      // 		from70to80:this.item.from70to80,
      // 		from80to90:this.item.from80to90,
      // 		out90:this.item.out90,
      // 	}
      // 	this.$api.setCinemaList(data).then(res => {
      // 		this.bjxgDialog = false
      // 		// console.log(res.data)
      // 		if(res.data.code == 0){
      // 			this.$message({
      // 				type:'success',
      // 				message:'修改成功'
      // 			})
      // 			this.getData()
      // 		}
      // 	}).catch(err => {
      // 		console.log(err)
      // 	})
      // }).catch(() => {
      // 	this.$message({
      // 		type: 'info',
      // 		message: '已取消删除'
      // 	});
      // })
    },
    // 批量操作按钮
    batchConfirm() {
      // 	this.$confirm('是否继续修改该配置?', '提示', {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消',
      // 	type: 'warning'
      // }).then(() => {
      // 	if(this.batch.tp == 2){
      // 		this.batch.tp = ''
      // 	}
      // 	this.$api.setSomeCinema(this.batch).then(res => {
      // 		this.plczDialog = false
      // 		if(res.data.code == 0){
      // 			this.$message({
      // 				type:'success',
      // 				message:'修改成功'
      // 			})
      // 			this.getData()
      // 		}
      // 		}).catch(err => {
      // 			console.log(err)
      // 		})
      // }).catch(() => {
      // 	this.$message({
      // 		type: 'info',
      // 		message: '已取消删除'
      // 	});
      // })
    },
  }, //methods
}
</script>

<style lang="less" scoped>
.info_box {
  div {
    height: 60px;
    text-align: left;
  }
  .info_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div {
      flex: 1;
    }
  }
}
// .el-form-item {
//   margin-bottom: 10px;
// }
// /deep/ .el-table_1_column_15 .cell {
//   overflow: visible;
// }
// /deep/ .el-input--suffix .el-input__inner {
// 	color: #409EFF !important;
// }
// /deep/ .el-input--suffix .el-input__inner{
// 	border-color: #ccc !important;
// }
</style>